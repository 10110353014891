// adapted from https://johnkavanagh.co.uk/writing/using-javascript-to-avoid-orphans/
export const catchOrphanedWords = (string: string, lastWordsStart: number) => {
	if (!string.length) {
		return string;
	}

	// trim whitespace and then split into an array of words
	const words =
		string[string.length - 1] === ' '
			? string.slice(0, -1).split(' ')
			: string.split(' ');
	const totalWords = words.length;

	// if there are fewer than four words or the last line will be too long, return the string as-is
	if (
		totalWords < 4 ||
		words.slice(totalWords - lastWordsStart).join(' ').length > 20
	) {
		return string;
	}

	// add nonbreaking space between the last words
	const lastWords = words.slice(totalWords - lastWordsStart).join('&nbsp;');

	// remove the last two words from the array
	words.splice(-lastWordsStart, lastWordsStart);

	// return the remaining words, re-joined with a space, followed by the last two words (wrapped in the nowrap span), as a JSX object
	return `${words.join(' ')} ${lastWords}`;
};
